@tailwind base;
@tailwind components;
@tailwind utilities;

body {
    /* @import url("https://fonts.cdnfonts.com/css/progress-3");
    font-family: "Progress", sans-serif; */
    /* font-family: "JetBrains Mono", monospace; */
    @import url("https://fonts.cdnfonts.com/css/wix-madefor-display");
    font-family: "Wix Madefor Display", sans-serif;
    margin: 0;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    background-color: black;
    color: white;
    min-width: 320px;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
        monospace;
}
